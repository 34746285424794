<template>
  <div class="page-container message-template-page">
    <portal to="page-name">Modelos de mensagem</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Modelos de mensagem</h1>
      </div>

      <template-list class="card-body" />
    </div>
  </div>
</template>

<script>
import TemplateList from './TemplateList.vue';

export default {
  components: {
    TemplateList,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
</style>
